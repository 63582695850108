import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { getUnixTime } from 'date-fns';
import Link from 'next/link';

interface Props {
  onClose: VoidFunction;
}

const BrDiv = styled('div')({
  height: 20,
});
const ActionBtn = styled('button')({
  width: '50%',
  height: 36.5,
  fontWeight: 300,
});

export const CloseModal = (props: Props) => {
  const recordTime = () => {
    localStorage.setItem('popup-close', String(getUnixTime(new Date())));
    props.onClose();
  };

  return (
    <div
      css={css`
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;
        width: 100vw;
        height: 100vh;
      `}
    >
      <div
        onClick={props.onClose}
        css={css`
          position: absolute;
          top: 0;
          left: 0;
          background: rgba(0, 0, 0, 0.5);
          width: 100%;
          height: 100%;
        `}
      />

      <div
        css={css`
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          width: 90%;
          height: auto;
          max-width: 400px;

          background: white;

          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 20px;
        `}
      >
        <h2
          css={css`
            width: 100%;
            flex: 0 0 auto;
            padding: 16px 12px 20px;
            font-weight: 500;
            letter-spacing: -1px;
            font-size: 20px;
            line-height: 1.6;
            margin: 0;
            text-align: center;
          `}
        >
          KT AI 보이스 스튜디오 서비스 종료 안내
          <div
            css={css`
              background: linear-gradient(
                to right,
                rgb(91, 43, 244),
                rgb(241, 36, 245)
              );
              width: 100%;
              height: 8px;
              margin-top: 4px;
            `}
          />
        </h2>

        <div
          css={css`
            text-align: center;
            font-size: 16px;
            letter-spacing: -1px;
            font-weight: 400;
            line-height: 1.5;
            color: rgba(0, 0, 0, 0.7);
            margin-bottom: 25px;
          `}
        >
          안녕하세요. KT AI 보이스 스튜디오입니다.
          <BrDiv />
          그동안 서비스를 이용해 주신 고객님께
          <br />
          진심으로 감사드립니다.
          <BrDiv />
          아쉽게도 KT AI 보이스 스튜디오가
          <br />
          2025년 3월 31일부로 종료됨을 안내 드립니다.
          <BrDiv />
          서비스가 종료되는 시점까지 불편을
          <br />
          최소화하기 위해 최대한 노력하겠습니다.
          <BrDiv />
          상세한 내용은{' '}
          <Link
            href={`/support/notice/view?id=${process.env.NEXT_PUBLIC_CLOSE_NOTICE_ID}`}
            onClick={props.onClose}
          >
            <span
              css={css`
                text-decoration: underline;
                color: rgb(84, 139, 246);
                cursor: pointer;
              `}
            >
              고객지원{' > '}공지사항
            </span>
          </Link>
          을 참고 부탁드립니다.
          <BrDiv />
          문의: 고객지원{' > '}문의하기 등록 바랍니다.
        </div>

        <div
          css={css`
            display: flex;
            width: 100%;
          `}
        >
          <ActionBtn
            style={{
              background: 'rgb(84, 139, 246)',
              color: 'rgb(255, 255, 255)',
            }}
            onClick={recordTime}
          >
            일주일 안보기
          </ActionBtn>
          <ActionBtn
            style={{
              borderWidth: 1,
              borderStyle: 'solid',
              borderColor: 'rgba(84, 139, 246, 0.5)',
              background: 'rgb(255, 255, 255)',
              color: 'rgb(84, 139, 246)',
            }}
            onClick={props.onClose}
          >
            닫기
          </ActionBtn>
        </div>
      </div>
    </div>
  );
};
